import { useState, useEffect } from "react";
import axios from "axios";
import shopping from "../../assets/shopping.svg";
import shoppingAssistant from "../../assets/shopping-assistant.svg";
import price from "../../assets/price.svg";
import discount from "../../assets/discount.svg";
import results from "../../assets/results.svg";
import send from "../../assets/submit-msg.svg";
import Header from "../../components/header/Header";
import Loader from "../../components/loader/Loader";

const MainContent = () => {
  const [query, setQuery] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popupVisible, setPopupVisible] = useState(true);
  const [threadId, setThreadId] = useState(null);

  const handleCreateThread = async () => {
    try {
      const res = await axios.post("https://api.buai.co/create_thread/");
      if (res.data && res.data.response) {
        console.log("Thread created:", res.data.response);
        setThreadId(res.data.response);
      } else {
        console.error("Unexpected response structure:", res.data);
      }
    } catch (error) {
      console.error("Error creating thread:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!query.trim() || !threadId) return;
    setChatHistory((prev) => [...prev, { type: "user", text: query }]);
    setQuery("");
    setLoading(true);

    try {
      const res = await axios.post(
        `https://api.buai.co/get_response/?thread_id=${threadId}`,
        { query }
      );

      console.log("API Response:", res.data);
      if (res.data && res.data.response) {
        setChatHistory((prev) => [
          ...prev,
          { type: "bot", text: res.data.response },
        ]);
      } else {
        console.error("Unexpected response structure:", res.data);
      }
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleCreateThread(); 
    setPopupVisible(true);
  }, []);

  const makeLinksClickable = (text) => {
  const regex = /(https:\/\/[^\s)]+)/g;

  return text.split("\n\n").map((block, index) => (
    <div key={index} className="mb-4">
      {block.split("\n").map((line, i) => (
        <div key={i} className={line.includes("Condition: Used") ? "mb-3" : "mb-2"}>
          {line.split(regex).map((part, j) =>
            regex.test(part) ? (
              <a
                key={j}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                here
              </a>
            ) : (
              <span key={j}>{part}</span> 
            )
          )}
        </div>
      ))}
    </div>
  ));
};

  
  return (
    <div className="flex flex-col xl:h-screen lg:h-screen md:h-screen h-[750px] overflow-hidden pb-2">
      <Header />

      <div className="flex flex-col items-center justify-between flex-1 text-center overflow-y-auto bg-[#F9FAFC] rounded-lg shadow-[inset_0px_-2px_14px_0px_rgba(0,0,0,0.08)] gap-2 px-4 lg:px-0">
        {chatHistory.length === 0 && (
          <div className="p-2 bg-white rounded-lg shadow-md mt-10 sm:mt-6">
            <img src={shopping} alt="BuAI Logo" className="max-w-full h-auto" />
          </div>
        )}
        {chatHistory.length === 0 && (
          <div className="flex flex-col items-center justify-center flex-grow">
            <h1 className="text-3xl font-bold">Welcome to ebuAI</h1>
            <p className="mt-4 text-sm sm:text-base text-[#5E5E5E] leading-relaxed">
              Introducing ebuAI, your free AI shopping assistant! No more
              endless scrolling - <br />
              just compare prices from different sellers across eBay in seconds.
              <br /> Let ebuAI do the hard work and find the best deals for you.
            </p>
            <div className="flex flex-col items-center justify-around w-full max-w-xs gap-4 mt-4">
              <div className="flex items-center gap-3 ms-6">
                <img src={shoppingAssistant} alt="AI Shopping Assistant" />
                <p className="text-sm">AI Shopping Assistant</p>
              </div>
              <div className="flex items-center gap-3">
                <img src={price} alt="Price Comparisons" />
                <p className="text-sm">Price Comparisons</p>
              </div>
              <div className="flex items-center gap-3 me-2">
                <img src={discount} alt="Discount Hunter" />
                <p className="text-sm">Discount Hunter</p>
              </div>
              <div className="flex items-center gap-3">
                <img src={results} alt="Real-Time Results" />
                <p className="text-sm">Real-Time Results</p>
              </div>
            </div>
          </div>
        )}
        <div className="w-full mt-6 p-4 flex-1 overflow-y-auto">
          {chatHistory.map((chat, index) => (
            <div
              key={index}
              className={`p-3 rounded mb-2 ${
                chat.type === "user"
                  ? "bg-gray-200 text-right"
                  : "bg-gray-100 text-left"
              }`}
              style={{ wordBreak: "break-word" }}
            >
              <strong>{chat.type === "user" ? "" : ""}</strong>
              <div>{makeLinksClickable(chat.text)}</div>
            </div>
          ))}
          {loading && (
            <div className="p-3 rounded">
              <div>
                <Loader />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="w-full pt-4 px-2">
        <form className="flex" onSubmit={handleSubmit}>
          <div className="flex-1 border border-[#B0B0B0] p-1 rounded-full md:p-2">
            <input
              type="text"
              placeholder="Tell me what do you want?"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="flex-1 p-1 w-full border-none outline-none text-[#5E5E5E] md:p-2"
              disabled={loading}
            />
          </div>
          <button
            type="submit"
            className="bg-[#0064D2] text-white xl:p-4 lg:p-4 md:p-2 md:px-4 px-3 p-2 ml-3 rounded-full"
            disabled={loading}
          >
            <img
              src={send}
              alt="Send"
              className="w-4 h-4 md:w-6 md:h-6 lg:w-auto lg:h-auto"
            />
          </button>
        </form>
      </div>
    </div>
  );
};

export default MainContent;
