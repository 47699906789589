import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const Header = () => {
  return (
    <div className="flex justify-end items-center px-4 py-3 bg-white">
      <div className="flex space-x-4 cursor-pointer">
        <a href="https://www.facebook.com/profile.php?id=61572312689155&sk=about" target="_blank" rel="noopener noreferrer">
          <FaFacebook className="text-2xl hover:text-blue-600" />
        </a>
        <a href="https://www.instagram.com/ebaychatbot" target="_blank" rel="noopener noreferrer">
          <FaInstagram className="text-2xl hover:text-pink-600" />
        </a>
      </div>
    </div>
  );
};

export default Header;
